import dayjs from 'dayjs';
import useSubscription from 'modules/Billing/hooks/useSubscription';
import { gql, useMutation } from 'modules/GraphQL';
import usePublicRuntimeConfig from 'modules/common/hooks/usePublicRuntimeConfig';
import React from 'react';

const SUBSCRIBE = gql`
  # Increments a back-end counter and gets its resulting value
  mutation createSubscription($input: createSubscriptionInput!) {
    createSubscription(input: $input) {
      item {
        id
        url
      }
    }
  }
`;

const SubscribeButton: React.FC<any> = () => {
  const [createSubscription, { loading }] = useMutation(SUBSCRIBE);
  const { subscription } = useSubscription();
  const { whatsappMartin } = usePublicRuntimeConfig();

  const handleSubmit = (input: any) => async () => {
    const { data } = await createSubscription({
      variables: { input: { ...input } },
    });

    if (data?.createSubscription?.item) {
      window.location.href = data?.createSubscription?.item?.url;
    }
  };

  if (subscription) {
    return (
      <div className="alert alert-primary text-left flex-col flex">
        <p>
          Félicitations 🎉, tu es abonné au <strong>plan {subscription?.type === 'monthly' ? 'mensuel' : 'annuel'}</strong>
        </p>
        <p>
          Celui-ci se renouvellera automatiquement le <strong>{dayjs(subscription.endAt).format('llll')}</strong>
        </p>
        <p className="mt-5">
          Pour annuler ton abonnement,{' '}
          <a href={whatsappMartin} target="_blank" rel="noreferrer" className="link">
            contacte-nous sur WhatsApp
          </a>
        </p>
      </div>
    );
  }

  return (
    <>
      <div className="btn-group mt-5">
        <button className="btn btn-primary" onClick={handleSubmit({ plan: 'monthly' })} disabled={loading}>
          300€/mois
        </button>
        <button className="btn btn-secondary flex-col leading-none" onClick={handleSubmit({ plan: 'yearly' })} disabled={loading}>
          3000€/an
          <small className="block">🎉 2 mois gratuits</small>
        </button>
      </div>
    </>
  );
};

export default SubscribeButton;
